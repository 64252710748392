.screen{
  max-height: 500px;
}

@media (min-width: 960px){
    .screen{
        height: 360px;
        max-height: 860px;
    }
}

@media (min-width: 1060px){
    .screen{
        height: 310px;
        max-height: 780px;
    }
}


@media (max-width : 960px){
    .screen{
    
        max-height: 100%;
    }
}