

.screen{
    display: flex;
    flex-direction: column;
    /* flex-flow: row wrap ; */
    
    /* overflow: hidden; */
    /* background-color: #f5f5f5; */
}

.carosolContainer{
    /* width: 95%; */
    height: 600px;
}

.quoteContainer{
    margin: auto;
    margin-bottom: 0%;
    width: 50%;
}

.aboutEventContainer{
    display: flex;
    flex-direction: row;
    margin: 5%;
    margin-bottom: 0%;
}

.aboutContainer{
    width: 60%;
    margin-right: 2%;
}

.eventContainer{
    width: 40%
}

.photoContainer{
    margin-top: 5%;
}
.contactConatiner{
    background: '#FF7247'
}





@media (max-width : 500px){
    .screen{
        display: flex;
    flex-flow: column ;
    
    }
}
@media (max-width : 960px){
    .aboutEventContainer{
        display: flex;
        flex-direction: column;
        margin: 5%;
        margin-top: 10%;
       
    
    }
    .aboutContainer{
        width: 80%;
       display: block;
       margin: auto;
    }
    
    .eventContainer{
        width: 80%;
        display: block;
       margin: auto;
    }
    .quoteContainer{
        margin: auto;
        display: block;
        width: 75%;
    }
}

@media (max-width : 660px){
    .aboutEventContainer{
        display: flex;
        flex-direction: column;
        margin: 5%;
        margin-top: 10%;
       
    
    }
    .aboutContainer{
        width: 90%;
       display: block;
       margin: auto;
    }
    
    .eventContainer{
        width: 90%;
        display: block;
       margin: auto;
    }
    .quoteContainer{
        margin: auto;
        display: block;
        width: 80%;
    }
}