.container{
    height: 32px;
    width: 32px;
    display:none;
    margin-left: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    cursor: pointer;
    padding: 4px;
}

@media(max-width: 780px){
    .container{
        height: 32px;
        width: 32px;
        display:flex;
        margin-left: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
        cursor: pointer;
        padding: 4px;
    }
}