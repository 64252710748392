.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 80%;
    
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px grey;
    padding: 8px;
    border-radius: 5px;
    left: 10%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

@media (min-width: 700px) {
    .Modal {
        width: 700px;
        left: calc(50% - 350px);
    }
}