.screen{
    max-height: 380px;
 }
 @media (min-width: 960px){
     .screen{
         height: 450px;
         max-height: 380px;
     }
 }
 @media (max-width : 960px){
     .screen{
         max-height: 100%;
     }
 }

 .flex{
     display: flex;
     flex-direction: column;
    align-items: flex-end;
     }



 .input{
    width: 80%;
    padding: 10px;
    margin: auto;
        outline: none;
        border: 2px solid #ccc;
        border-radius: 2px;
        background-color: white;
        font: inherit;
        padding: 6px 10px;
        display: block;
        box-sizing: border-box;
 }

 .buttonContainer{
     margin-right: 10%;
     margin-top: 20px;
 }
 .button{
    width: 100px;

 }

 .textarea:focus{
   
    background-color: whitesmoke;
 }
    
    .input:focus {
        outline: none;
        background-color: whitesmoke;
    }
